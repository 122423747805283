
const menuItem = $('.menu__item');
const menuItemParent = $('.menu__item-parent');
const menuDropdown = $('.menu__dropdown');

$(menuItemParent).click(function() {
  $(menuItem).removeClass('js-toggle');
  $(menuItemParent).removeClass('js-toggle');
  $(menuDropdown).removeClass('js-toggle');
  $(this).toggleClass('js-toggle');
  $(this).parent().toggleClass('js-toggle');
  $(this).parent().find('.menu__dropdown').toggleClass('js-toggle');
});

//toggle menu
const menuToggle = $('.m-toggle');
const menu = $('.menu');
const content = $('.content');

$(menuToggle).click(function() {
  $(this).toggleClass('js-show');
  $(menu).toggleClass('js-show');
  $(content).toggleClass('js-show');
});


//toggle user
const userInfo = $('.user-info__n');
const userToggle = $('.user-info__t');

$(userInfo).click(function() {
  $(this).toggleClass('js-show');
  $(userToggle).toggleClass('js-show');
});
