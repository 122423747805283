$('[data-action="report-type-selector"]').change(function(){
    
    console.log("switch");
    console.log($(this).find('option:selected'));
    
    var type = $(this).find('option:selected').attr('data-year_type');
    console.log(type);

    // console.log("..."+reportType);

    switch(type){
        case 'single_year': 
            $('[name="company_year_id"]').attr('required', true);
            break;
        case 'multi_year':
            $('[name="company_year_id"]').attr('required', false);
            $('[name="company_year_ids"]').attr('required', false);
            break;
    }

    $('.js-report_year_type').hide();
    $('.js-year_type_'+type).show();
    // $reportType.filter('[data-report-type="' + selectedType + '"]').show();
});

$('[name="report_form"]').submit(function(){
    let submit_button = $('.js-report_submit');
    submit_button.attr('disabled', true);
    $('.js-multi_year_hint').removeClass('text-danger');
    var type = $('[data-action="report-type-selector"]').find('option:selected').attr('data-year_type');
    if(type == 'multi_year'){
        var checked = $('[name="company_year_ids[]"]:checked').length;
        if(checked < 2 || checked > 3){
            $('.js-multi_year_error').show();
            submit_button.attr('disabled', false);
            return false;
        }
    }
    if($(this)[0].checkValidity()){
        return true;
    }else{
        submit_button.attr('disabled', false);
        return false;
    }
});