//-------------------------------------------
// start register
//-------------------------------------------
$(document).ready(function(){
    $(".toggle-password").click(function() {
        $(this).toggleClass("field-icon--on");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
        input.attr("type", "text");
        } else {
        input.attr("type", "password");
        }
    });
});

$(document).ready(function(){

  $('[name="province_id"]').change(function(){
      $.post($(this).data('ajax_url'), { 'province_id':$(this).val(), '_token': $('form input[name="_token"]').val() }, function( data ) {
          $("[name='city_id']").html('');
          $("[name='city_id']").append('<option value="" selected></option>');
          for(var i=0;i<data.length;i++) {
              $("[name='city_id']").append('<option value='+ data[i].id +'>'+data[i].name+ '</option>');
          }

      });
  });
});


var fraction = document.getElementById("fraction");
if(fraction != null){
    var slides = document.querySelectorAll(".swiper-slide");
    var slideCount = slides.length;
    fraction.innerHTML = `<span>1</span>/${slideCount}`;
    var swiper = new Swiper(".formSwiper", {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 0,
        grabCursor: false,
        allowTouchMove: false,
        keyboard: {
            enabled: false,
        },
        pagination: {
            el: ".swiper-pagination",
        },
        on: {
            slideChange: () => {
            fraction.innerHTML = ` <span>${swiper.realIndex + 1}</span>/${slideCount} `;
            }
        }
      });
}

$(document).ready(function() {
    /* $("#password-message").hide(); */
    $("#slide-message-1").hide();
    $("#slide-message-2").hide();
    $('#swiper-finish-message').hide();

    $('#registerModal').on('shown.bs.modal', function() {
        swiper.slidePrev();
        swiper.slidePrev();
        cleanForm();
        /* $form.trigger('reset'); */
        $('#swiper-finish-box').show();
        $('#swiper-finish-message').hide();
        $.ajax({
            url: '/get_provinces',
            method: 'GET',
            dataType: 'json',
            success: function(response) {
                $('select[name="province_id"]').empty();

                $.each(response, function(index, province) {
                    $('select[name="province_id"]').append('<option value="' + province.id + '">' + province.name + '</option>');
                });
            },
            error: function(xhr, status, error) {
                console.log(error);
            }
        });
    });
});

$('select[name="province_id"]').change(function() {

    var provinceId = $(this).val();

    $.ajax({
        url: '/get_cities',
        method: 'GET',
        dataType: 'json',
        data: { province_id: provinceId },
        success: function(response) {
            $('select[name="city_id"]').empty();

            $.each(response, function(index, city) {
                $('select[name="city_id"]').append('<option value="' + city.id + '">' + city.name + '</option>');
            });
        },
        error: function(xhr, status, error) {
            console.log(error);
        }
    });
});

$("#swiper-button-1").on("click", function (e) {
    $("#password-message").hide();
    $("#slide-message-1").hide();
    var name = $("#name").val();
    var email = $("#email").val();
    var password = $("#password-register").val();
    var passwordrepeat = $("#password-repeat-register").val();
    var validate_password = true;
    var validate_email = true;

    var companyExists;

    if (name != '' && email != '' && password != '' && passwordrepeat != '') {
        // Realizar la solicitud AJAX a la ruta correspondiente
        $.ajax({
            url: '/check-company-exists/' + email, // Reemplaza con la ruta correcta en tu aplicación
            method: 'get',
            data: {
                email: email
            },
            async: false, // Establecer async a false para esperar la respuesta antes de continuar
            success: function(response) {
                // El servidor ha respondido con éxito
                companyExists = response;
            },
            error: function(xhr, status, error) {
                // Ocurrió un error en la solicitud
                console.log('Error en la solicitud:', error);
            }
        });

        if (companyExists) {
            $("#slide-message-1").text($("#email").data('msg_already_exists')).show();
        }else{

            // Verificar el formato del correo electrónico
            var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                validate_email = false;
                $("#slide-message-1").text($("#email").data('format')).show();
            }else{
                //Comprobacions del password
                // Verificar que tenga al menos 8 caracteres y recomendado más de 10
                if (password.length < 8) {
                    validate_password = false;
                    $("#slide-message-1").text($("#email").data('characters')).show();
                }

                // Verificar que tenga caracteres especiales
                var specialChars = /[!@#$%^&*(),.?":{}|<>]/;
                if (!specialChars.test(password)) {
                    validate_password = false;
                    $("#slide-message-1").text($("#email").data('special')).show();
                }

                // Verificar combinación de mayúsculas y minúsculas
                var hasUpperCase = /[A-Z]/;
                var hasLowerCase = /[a-z]/;
                if (!hasUpperCase.test(password) || !hasLowerCase.test(password)) {
                    validate_password = false;
                    $("#slide-message-1").text($("#email").data('min_mayus')).show();
                }
            }
        }

        if (name != '' && validate_email && validate_password && passwordrepeat != '' && companyExists != true) {
            if (password == passwordrepeat) {
                swiper.slideNext();
            } else {
                $("#slide-message-1").text($("#password").data('samepass')).show();
                swiper.slidePrev();
            }
        }
    }else {
        $("#slide-message-1").text($("#email").data('noempty')).show();
    }



});

$("#swiper-button-2").on("click", function (e) {
    $("#password-message").hide();
    $("#slide-message-2").hide();
    var companyname = $("#companyname").val();
    var nif = $("#nif").val();
    var worker_name = $("#worker_name").val();
    var cnae = $("#cnae").val();
    var workers = $("#workers").val();

    if (companyname != '' && nif != '' && worker_name != '' && cnae != '' && workers != '') {
        // Validar que cnae sea numérico y tenga un máximo de 4 cifras
        var isValidCnae = /^\d{1,4}$/.test(cnae);
        if (isValidCnae) {
            // Validar que nif tenga el formato correcto (formato español de NIF)
            var isValidNifOrCifOrNie = /^([XYZ]?\d{7,8}[A-Z]|^[ABCDEFGHJNPQRSUVW]\d{7}[0-9A-J]|^[XYZ]\d{7,8}[A-Z])$/i.test(nif);
            if (isValidNifOrCifOrNie) {
                // Validar que workers sea numérico
                var isValidWorkers = /^\d+$/.test(workers);
                if (isValidWorkers) {
                    $.ajax({
                        type: "GET",
                        url: "/get_nif",
                        data: { nif: nif },
                        success: function (response) {
                            if (response.exists) {
                                // NIF existe, puedes manejarlo aquí
                                $("#slide-message-2").text($("#nif").data('nif_register')).show();
                            } else {
                                // NIF no existe, continuar con el siguiente paso
                                swiper.slideNext();
                            }
                        },
                        error: function (error) {
                            console.error("Error en la solicitud AJAX:", error);
                        }
                    });
                } else {
                    $("#slide-message-2").text($("#workers").data('only_num')).show();
                }
            } else {
                $("#slide-message-2").text($("#nif").data('cif_incorrect')).show();
            }
        } else {
            $("#slide-message-2").text($("#cnae").data('cnae_incorrect')).show();
        }
    } else {
        $("#slide-message-2").text($("#companyname").data('noempty')).show();
    }
});

// Evita que el formulario se pase con enter
/* $(document).ready(function() {
    $('#myForm').on('keydown', function(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }); */

//   var swiperContainer = document.getElementsByClassName('formSwiper');

//   swiperContainer[0].addEventListener('keydown', function(event) {
//     if (event.key === 'Tab' || event.key === 'Enter') {
//       event.preventDefault(); // Evita cambiar de elemento al presionar "Tab"
//     }
//   })


$('.formSwiper').keydown(function(event) {
  if (event.key === 'Enter') {
    event.preventDefault(); // Evita cambiar de elemento al presionar "Tab"
  }
})

  $('#password-repeat-register').keydown(function(e) {
    if (e.keyCode == 9) {
      e.preventDefault();
    }
  });

  $('#workers').keydown(function(e) {
    if (e.keyCode == 9) {
      e.preventDefault();
    }
  });

  $('input[id="postal_code"]').keydown(function(e) {
    if (e.keyCode == 9) {
      e.preventDefault();
    }
  });


$("#register_form").on("submit", function (e) {
    var address = $("#address").val();
    var number = $("#number").val();
    var postal_code = $("#postal_code").val();
    if (address != '' && number != '' && postal_code != '') {
        e.preventDefault();
        $form = $(this);
        $btn = $form.find('[type="submit"]');
        $btn.prop("disabled", true);

        /* $btn.addEventListener('click', function() {
            swiper.slideNext();
        }); */
        var formData = $form.serialize();

        $.post($form.data('action'), formData, function( response ) {
            var message = response.message;
            if(response.success == 1) {
                /* showFeedback(response.message, 'success'); */
                $form.trigger('reset');
                /* $('.js-form-wrapper').hide(); */
                $('#swiper-finish-box').hide();
                $('#swiper-finish-message').show();
                //LLAMAR A OTRO MODAL
            }else{
                showFeedback(response.message, 'warning');
            }
        })
        .fail(function(error) {
            if (error.status == 422) {
                var data = error.responseJSON;
                showFeedback(data.message, 'danger', false, data.errors);
            } else {
                showFeedback($form.data('error_text'), 'danger');
            }
        })
        .always(function() {
            $btn.prop("disabled", false);
        })
    } else {
        $("#slide-message-2").text($("#companyname").data('noempty')).show();
    }
});

function cleanForm() {
    var form = document.getElementById("register_form");
    for (var i = 0; i < form.elements.length; i++) {
      var element = form.elements[i];
      if (
        element.type === "text" ||
        element.type === "textarea" ||
        element.type === "password" ||
        element.type === "email"
      ) {
        element.value = "";
      }
    }
  }

//-------------------------------------------
// end register
//-------------------------------------------

//-------------------------------------------
// start login
//-------------------------------------------
/* $("#login_form").on("submit", function (e) {

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);
    formData = $form.serialize();

    $.post($form.data('action'), formData, function( response ) {
        if (response.success == 1) {
            window.location.href = $form.data('redirect_to');
        } else {
            showFeedback(response.message, 'warning');
        }
    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            showFeedback(data.message, 'danger', false, data.errors);
        } else {
            showFeedback($form.data('error_text'), 'danger');
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })

}) */

$("#login_form").on("submit", function (e) {
    e.preventDefault();
    $form = $(this);

    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);

    // Agregar el token CSRF al formulario
    var formData = new FormData($form[0]);
    /* alert(window.Laravel.csrfToken);
    formData.append('_token', $('meta[name="csrf-token"]').attr('content')); */
    var csrfToken = $('meta[name="csrf-token"]').attr('content');
    /* console.log(formData);
    alert(csrfToken); */
    /* console.log(formData);
    console.log($form); */
    if (csrfToken) {
        formData.append('_token', csrfToken[1]);
    }

    $.ajax({
        url: $form.data('action'),
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: function (response) {
            if (response.success == 1) {
                window.location.href = response.redirect_to;
            } else {
                showFeedback(response.message, 'warning');
            }
        },
        error: function (error) {
            if (error.status == 422) {
                var data = error.responseJSON;
                showFeedback(data.message, 'danger', false, data.errors);
            } else {
                showFeedback($form.data('error_text'), 'danger');
            }
        },
        complete: function () {
            $btn.prop("disabled", false);
        }
    });
});
//-------------------------------------------
// end login
//-------------------------------------------

//-------------------------------------------
// start forgot password
//-------------------------------------------
$("#password_form").on("submit", function (e) {

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);

    $.post( $form.data('action'),{
        email: $form.find("[name='forgot_password_email']").val(),
        _token: $form.find("[name='_token']").val()
    }, function (response) {

        if(response.success == 1) {
            /* showFeedback(response.message, 'success'); */
            $form.trigger('reset');
            $('.js-form-wrapper').hide();
            $('#forgot-message').text($("#slide-message-forgot").data('error_password')).show();
        }else{
            /* showFeedback(response.message, 'warning'); */
            $("#slide-message-forgot").text($("#slide-message-forgot").data('email_error')).show();
        }


    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            showFeedback(data.message, 'danger', false, data.errors);
        } else {
            showFeedback("@lang('front/authentication.feedback.general_error')", 'danger');
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })
})

//-------------------------------------------
// end forgot password
//-------------------------------------------

//-------------------------------------------
// start reset password
//-------------------------------------------

$(".toggle-passwordone").click(function() {

    $(this).toggleClass("field-icon--on");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
});

/* $('#exampleModal').on('shown.bs.modal', function() {
    $('.js-feedback').hide();
}); */

$("#pass_form").on("submit", function (e) {

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);
    formData = $form.serialize();
    $.post( $form.data('action'), formData, function (response) {
        console.log(response);
        if(response.success == 1) {
            showFeedback(response.message, 'success', false);
            $form.trigger('reset');
            $('.js-form-wrapper').hide();
        }else{
            /* alert(response.message); */
            showFeedback(response.message, 'warning', false);
        }
    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            $("#slide-message-update").text("La contraseña debe tener al menos 8 caracteres, un carácter especial, una combinación de mayúsculas y minúsculas y tienen que coincidir.").show();
        } else {
            /* showFeedback($form.data('error_text'), 'danger'); */
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })

})

//-------------------------------------------
// end reset password
//-------------------------------------------

function showFeedback(message, alert_type, expires = false, errors = {}) {
    console.log("show message");
    console.log(message);
    console.log("errors:");
    console.log(errors);
    var feedback = document.querySelector('.js-feedback');
    // Reset feedback content
    feedback.innerHTML = message;

    var container = document.createElement('div');
    container.className = 'alert ' + 'alert-' + alert_type;

    // If errors
    if (Object.keys(errors).length > 0) {
        console.log("is an object");
        var errorUl = document.createElement('ul');
        errorUl.style.margin = 0;
        errorUl.style.paddingLeft = "20px";

        Object.values(errors).forEach(function (error, i) {
           /*  error.forEach(function (err) {
                var errorLi = document.createElement('li');
                errorLi.innerText = err;
                errorUl.appendChild(errorLi);
            }) */
        });
        console.log(container);
        container.appendChild(errorUl);
    } else {
        console.log("is NOT an object");
        /* var text = document.createElement('div');
        text.innerHTML = message;
        container.appendChild(text); */
    }

    // Show feedback
    feedback.appendChild(container);

    if (expires) {
        setTimeout(function() {
            feedback.innerHTML = '';
        }, 6000);
    }
}
