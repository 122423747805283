var blogswiper = new Swiper(".blogSwiper", {
  slidesPerView: 1,
  spaceBetween: 0,
  grabCursor: false,
  // allowTouchMove: false,
  pagination: {
      el: ".swiper-pagination",
  },
});

