$("[data-action='submit_meta']").on("submit", function (e) {

    e.preventDefault();

    $form = $(this);

    $('.js-feedback_ko').html("").hide();
    $('.js-feedback_ok').html("").hide();

    var meta_id = $form.data("meta_id");

    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);

    $.post( $form.attr('action'),
    $form.serialize()
    , function (response) {
        console.log("disable 1 ");
        $btn.prop("disabled", false);
        if(response.success == 1) {
            $('.js-feedback_ko').html("").hide();
            $('.js-feedback_ok[data-meta_id="'+meta_id+'"]').html(response.message).show().delay(2000).fadeOut();
            $('.js-completed_company_meta_count').html(response.data.completed_company_meta_count);
            $('.js-completed_company_meta_percentile').html(response.data.completed_company_meta_percentile+'%');
            $('.js-completed_company_meta_percentile_width').css('width',response.data.completed_company_meta_percentile+'%');
            if(response.data.completed_company_meta_percentile>=100){
                $('.js-percentile_not_completed').hide();
                $('.js-percentile_completed').show();
            }else{
                $('.js-percentile_completed').hide();
                $('.js-percentile_not_completed').show();
            }
            $('[data-meta_id="'+meta_id+'"][data-action="switch_meta_lang"]').each(function(){
                if($form.find("[name='value_"+$(this).data('lang')+"']").val().trim().length!=""){
                    $(this).addClass('completed');
                }else{
                    $(this).removeClass('completed');
                }
            });
        }else{
            $('.js-feedback_ok').html("").hide();
            $('.js-feedback_ko[data-meta_id="'+meta_id+'"]').html(response.message).show().delay(2000).fadeOut();
        }
    })
    .fail(function(error) {
        console.log("disable 2 ");
        $btn.prop("disabled", false);
        if (error.status == 422) {
        } else {
        }
    })
    .always(function() {
        console.log("disable 3 ");
        $btn.prop("disabled", false);
    })
});

$("[data-action='switch_meta_lang']").on("click", function (e) {
    var lang = $(this).data('lang');
    var meta_id = $(this).data('meta_id');
    $('[data-meta_id="'+meta_id+'"][data-action="switch_meta_lang"]').removeClass('active');
    $(this).addClass('active');
    $('.js-meta_lang_wrapper[data-meta_id="'+meta_id+'"]').hide();
    $('p.js-meta_lang_label[data-meta_id="'+meta_id+'"]').hide();
    $('.js-meta_lang_wrapper[data-meta_id='+meta_id+'][data-lang='+lang+']').show();
    $('p.js-meta_lang_label[data-meta_id="'+meta_id+'"][data-lang='+lang+']').show();
    $('form[data-meta_id="'+meta_id+'"] .custom-checkbox-label[data-for="yes"]').html($('form[data-meta_id="'+meta_id+'"] .custom-checkbox-label[data-for="yes"]').data('label_'+lang));
    $('form[data-meta_id="'+meta_id+'"] .custom-checkbox-label[data-for="no"]').html($('form[data-meta_id="'+meta_id+'"] .custom-checkbox-label[data-for="no"]').data('label_'+lang));
    
});
